import React from "react";
import {PagePoste} from 'amg-it-theme'; 

let pageData ={
  header: {
    background: "metiers",
    title: "commerce",
    path: "accueil / nos métiers / techniques / Ingénieur Commercial"
  },
  title:  "Ingénieur Commercial", 
	missions:[
		{
		  text: "Mettre en œuvre les actions commerciales afin d’atteindre un objectif défini de chiffre d’affaires" 
		},
		{
		  text: "Constituer, gérer et développer un portefeuille de prospects et de clients sur un secteur d’activité défini" 
		},
		{
		  text: "Participer aux réponses aux appels d’offres" 
		},
	],
	key_skills: [
		{
		  text: "Communiquer avec charisme et conviction" 
		},
		{
		  text: "Mobiliser son énergie" 
		},
		{
		  text: "Etre orienté business" 
		},
		{
		  text: "Innover" 
		},
		{
		  text: "Analyser et comprendre" 
		},
	],
	prerequisites:[
		{
		  text: "Maitrise du mode contractuel de l’infogérance" 
		},
		{
		  text: "Expression écrite et orale" 
		},
		{
		  text: "Connaissance" 
		},
		{
		  text: "Formation supérieure type école de commerce ou équivalent." 
		},
	 ],
	activities:[
		{
		  text: "Identifier les clients potentiels à l’aide de l’outil de gestion commerciale et mettre en œuvre la prospection commerciale" 
		},
		{
		  text: "Détecter, cerner et analyser les besoins des prospects ou clients et identifier les circuits de décision" 
		},
		{
		  text: "Solliciter les autres services pertinents (Avant-vente, Opérations, Marketing…) et présenter une offre commerciale adaptée" 
		},
		{
		  text: "Négocier avec les clients, en accord avec sa hiérarchie, les prix, les délais, les quantités et les éléments du contrat" 
		},
		{
		  text: "Participer à la signature du contrat" 
		},
		{
		  text: "Suivre et développer les relations commerciales avec les clients" 
		},
		{
		  text: "Assurer la mise à jour du reporting commercial" 
		}
	]
}

const LocalPage = () => (
  <PagePoste {...pageData}/>
);

export default LocalPage;
